// variables
$black: #282c34;
$blue: #008ff7;
$turquoise: #47C4E1;
$white: #ffffff;
$gray: #919191;
$mediumGray: #CCCACD;
$lightGray: #E1E1E1;
$red: #F28282;
$deepRed: #952c2c;
$orange: #ee9240;

$breakpoint-phone: 480px;

html, body {
  height: 100%;
  min-height: 100% !important;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}

@import 'layout';
@import 'grid';
@import 'typography';
@import 'forms';
@import 'table';
@import 'components/alert';
@import 'components/button';
@import 'components/header';
@import 'components/navigation';
@import 'components/panel';
@import 'components/balance-row';
@import 'components/plausible';

@import 'screens/login-screen';
@import 'screens/dashboard';
@import 'screens/data-vault';
@import 'screens/identity';
@import 'screens/defi';
