::-webkit-input-placeholder, ::-webkit-textarea-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

input[type=text].line, textarea.line, select.line {
  margin-left: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid $gray;
  font-size: 16px;
}

input:disabled, textarea:disabled {
  cursor: not-allowed;
}

fieldset {
  border: none;

  p {
    display: flex;
    flex-direction: row;

    label {
      flex: 1;
      text-align: right;
      padding-right: 10px;

      span {
        display: block;
        font-size: 12px;
      }
    }

    input, textarea, select {
      flex: 3;
      width: 100%;
    }
  }
}

input.fullWidth {
  width: 100%
}
