.data-vault {
  .add-declarative {
    p.title {
      font-weight: 700 !important;
      text-transform: uppercase;
      color: $gray;
      margin-bottom: 0;
    }

    input.type {
      margin-top: 21px;
    }

    textarea.content {
      height: 38px;
      width: 100%
    }

    .submitColumn {
      text-align: right;
    }

    .submit {
      margin-top: 33px;
    }
  }

  .display {
    table {
      th.type {
        width: 25%;
      }

      th.content {
        text-align: left;
        padding-left: 15px;
      }
    }

    .content-row:not(:last-child) {
      border-bottom: 1px solid $lightGray;
    }

    .decrypt {
      text-align: right;
    }

    .content-row {
      width: 100%;
      display: flex;
      flex-direction: row;

      .content {
        flex: 5;
        text-align: left;
      }

      .options {
        flex: 1;
      }
    }

    .edit-modal {
      label {
        display: block;
        font-weight: bold;
        padding-bottom: 5px;
      }

      textarea {
        width: 100%;
        min-height: 35px;
        border: 1px solid $lightGray;
        padding: 5px;
      }
    }
  }

  .credentials {
    textarea {
      width: 100%;
      cursor: pointer;
      min-height: 250px;
    }

    div.raw {
      border: 1px solid $gray;
      background-color: #efefef;
      padding: 10px;
      font-size: 13px;
    }

    textarea.sharableLink {
      min-height: 1em;
    }
  }

  .alert {
    width: auto !important;
  }
}

@media screen and (max-width: $breakpoint-phone) {
  .data-vault {
    .add-declarative {
      input.type, .submit {
        margin-top: 0;
      }
    }
  }
}

