#analytics {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  background-color: rgba(0, 143, 247, .6);

  p {
    width: 85%;
    max-width: 750px;
    margin: 3px auto;
    font-size: 85%;
    line-height: 1.25em;
  }

  button {
    background: none;
    border: none;
    padding: 5px 10px;
    margin: 5px 10px 0 10px;
  }

  button#accept {
    background-color: $blue;
    color: #fff;
  }

  button#reject {
    background-color: #e1e1e1;
    color: #666;
  }

  button:hover {
    opacity: .8;
  }
}
