.dashboard {
  .identity-information {
    .advancedToggle {
      border: none;
      background: none;
      cursor: pointer;
      color: $blue;
      font-size: 14px;
    }

    p.value {
      color: $black;
      font-size: 18px;
    }
    ul.value {
      margin: 10px 0;
      padding: 0;
      list-style-type: none;
      li {
        font-size: 18px;
        color: $black;
      }
    }
  }

  .dataVault {
    .columnDouble {
      padding: 0;

      .tooltip-progress {
        display: block;
        width: 100%;
      }
    }

    .column {
      padding: 0;
      text-align: right;

      button {
        padding: 10px 25px;
      }
    }
  }

  .defi {
    button {
      float: right;
    }
    padding-bottom: 45px;
  }
}

@media screen and (max-width: $breakpoint-phone) {
  .dashboard {
    button.panel-cta {
      display: block;
      position: relative;
      bottom: auto;
      right: auto;
      margin-top: 15px;
    }
  }
}
